import React from "react";

import {
  Box,
  Button,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  Grid,
  TextField,
  MenuItem,
  // Customizable Area Start
  Typography,Input,styled
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import * as Yup from "yup";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ImageUploader from "./components/ImageUploader.web";
import CancelWarningPopup from "../../user-profile-basic/src/components/CancelWarningPopup.web";
import Calendar from "./components/Calendar";

// Customizable Area End

import MultipageFormsController, {
  Props,
  Gender,
  Country,
  Industry,
} from "./MultipageFormsController";
import { Formik } from "formik";

interface CountryOptionType {
  name?: string;
  dial_code?: string;
  code?: string;
}

const phoneRegExp = /^\+?\d{7,14}$/;
const FormSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Field is Too Short")
    .max(50, "Field is Too Long!")
    .required("First Name is required"),
  last_name: Yup.string()
    .min(2, "Field is Too Short")
    .max(50, "Field is Too Long!")
    .required("Last Name is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  phone_number: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone Number is required")
    .min(10, "Phone Number mush to 10 digit")
    .max(12, "Phone Number mush be less than 12 digit"),
});

const FormSchemaStep2 = Yup.object().shape({
  message: Yup.string().required("Message is required"),
  selectedCountry: Yup.string().required("Country is required"),
  selectedIndustry: Yup.string().required("Industry is required"),
  selectedGender: Yup.string().required("Gender is required"),
});

export default class MultipageForms extends MultipageFormsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getStepContent(step: number) {
    const { formikData } = this.state;
    switch (step) {
      case 0:
        return (
          <Formik
            initialValues={
              formikData.email
                ? formikData
                : {
                    first_name: "",
                    last_name: "",
                    selectedPhoneCountry: "+91",
                    phone_number: "",
                    email: "",
                    countries: this.state.countries,
                  }
            }
            validationSchema={FormSchema}
            enableReinitialize
            onSubmit={(values) => this.stepOnSubmit(values)}
          >
            {({ values, errors, setFieldValue, touched, handleSubmit }) => (
              <>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <FormControl style={{ width: "39rem" }}>
                      <TextField
                        id="outlined-basic"
                        data-test-id="txtInputfirstname"
                        label="First Name"
                        variant="outlined"
                        value={values.first_name}
                        onChange={(events) =>
                          setFieldValue("first_name", events.target.value)
                        }
                      />
                      {errors.first_name && touched.first_name && (
                        <div style={webStyle.errorMsg}>{errors.first_name}</div>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl style={{ width: "39rem" }}>
                      <TextField
                        id="outlined-basic"
                        data-test-id="txtInputlasttname"
                        label="Last Name"
                        variant="outlined"
                        value={values.last_name}
                        onChange={(events) =>
                          setFieldValue("last_name", events.target.value)
                        }
                      />
                      {errors.last_name && touched.last_name && (
                        <div style={webStyle.errorMsg}>{errors.last_name}</div>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl style={{ width: "39rem" }}>
                      <TextField
                        id="outlined-basic"
                        data-test-id="txtInputemail"
                        label="Email"
                        variant="outlined"
                        value={values.email}
                        onChange={(events) =>
                          setFieldValue("email", events.target.value)
                        }
                      />
                      {errors.email && touched.email && (
                        <div style={webStyle.errorMsg}>{errors.email}</div>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ width: "40rem", margin: "10px" }}>
                    <TextField
                      id="outlined-basic"
                      data-test-id="txtInputCountryPhonenumber"
                      variant="outlined"
                      select
                      label="Country"
                      value={values.selectedPhoneCountry}
                      onChange={(events) =>
                        setFieldValue(
                          "selectedPhoneCountry",
                          events.target.value
                        )
                      }
                    >
                      {this.state.countries.map((option: CountryOptionType) => (
                        <MenuItem
                          key={option.dial_code}
                          value={option.dial_code}
                        >
                          {option.dial_code}
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormControl>
                      <TextField
                        id="outlined-basic"
                        data-test-id="txtInputphonenumber"
                        label="Phone Number"
                        variant="outlined"
                        value={values.phone_number}
                        style={{ width: "543px" }}
                        onChange={(events) =>
                          setFieldValue("phone_number", events.target.value)
                        }
                      />
                      {errors.phone_number && touched.phone_number && (
                        <div style={webStyle.errorMsg}>
                          {errors.phone_number}
                        </div>
                      )}
                      {errors.email && touched.selectedPhoneCountry && (
                        <div style={webStyle.errorMsg}>
                          {errors.selectedPhoneCountry}
                        </div>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ width: "39rem", margin: "2rem" }}>
                    <FormControl
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Button
                        data-test-id="submitNextButton"
                        id="nextButton"
                        style={{ marginLeft: "20px" }}
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => handleSubmit()}
                      >
                        Next
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
          </Formik>
        );
      case 1:
        return (
          <>
            <Formik
              initialValues={{
                message: "",
                selectedCountry: "1",
                selectedGender: "1",
                selectedIndustry: "education",
              }}
              validationSchema={FormSchemaStep2}
              onSubmit={(values) =>
                this.multiPageFormSubmit(
                  Object.assign(this.state.formikData, values)
                )
              }
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Gender"
                      data-test-id="txtInputGender"
                      style={{ width: "40rem" }}
                      value={values.selectedGender}
                      onChange={(events) =>
                        setFieldValue("selectedGender", events.target.value)
                      }
                      variant="outlined"
                    >
                      {this.state.gender.map((option: Gender) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.selectedGender && (
                      <div style={webStyle.errorMsg}>
                        {errors.selectedGender}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Country"
                      data-test-id="txtInputCountry"
                      style={{ width: "40rem" }}
                      value={values.selectedCountry}
                      onChange={(events) =>
                        setFieldValue("selectedCountry", events.target.value)
                      }
                      variant="outlined"
                    >
                      {this.state.country.map((option: Country) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.selectedCountry && (
                      <div style={webStyle.errorMsg}>
                        {errors.selectedCountry}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-select-currency"
                      data-test-id="txtInputIndustry"
                      select
                      label="Industry"
                      style={{ width: "40rem" }}
                      value={values.selectedIndustry}
                      onChange={(events) =>
                        setFieldValue("selectedIndustry", events.target.value)
                      }
                      variant="outlined"
                    >
                      {this.state.industry.map((option: Industry) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.selectedIndustry && (
                      <div style={webStyle.errorMsg}>
                        {errors.selectedIndustry}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl style={{ width: "40rem" }}>
                      <TextField
                        id="outlined-basic"
                        data-test-id="txtInputMessage"
                        label="Message"
                        variant="outlined"
                        value={values.message}
                        onChange={(events) =>
                          setFieldValue("message", events.target.value)
                        }
                      />
                      {errors.message && (
                        <div style={webStyle.errorMsg}>{errors.message}</div>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ width: "39rem", margin: "2rem" }}>
                    <FormControl
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Button
                        variant="contained"
                        id="backButton"
                        data-test-id="backButton"
                        color="secondary"
                        disabled={this.state.activeSteps === 0}
                        onClick={this.handleBack}
                      >
                        Back
                      </Button>
                      <Button
                        style={{ marginLeft: "20px" }}
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </>
        );
      case 2:
        return (
          <>
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Box className="responseSection">
                  <p style={webStyle.successMsg}>{this.state.successData}</p>
                  <p style={webStyle.errorMsg}>{this.state.errorsData}</p>
                </Box>
                <h1 style={{ color: "#df175d" }}>User Details</h1>
                <TextField
                  id="outlined-basic"
                  data-test-id="txtInputFirstName"
                  label="Name"
                  variant="outlined"
                  value={this.state.first_name}
                />
                <TextField
                  id="outlined-basic"
                  data-test-id="txtInputLastName"
                  label="Name"
                  variant="outlined"
                  value={this.state.last_name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  data-test-id="txtInputemail"
                  label="Email"
                  variant="outlined"
                  value={this.state.email}
                />
                <TextField
                  id="outlined-basic"
                  data-test-id="txtInputPhone"
                  label="Phone"
                  variant="outlined"
                  value={this.state.phone_number}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  data-test-id="txtInputGender"
                  label="Gender"
                  variant="outlined"
                  value={this.state.selectedGender}
                />
                <TextField
                  id="outlined-basic"
                  data-test-id="txtInputCountry"
                  label="Country"
                  variant="outlined"
                  value={this.state.selectedCountry}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  data-test-id="txtInputemail"
                  label="Industry"
                  variant="outlined"
                  value={this.state.selectedIndustry}
                />
                <TextField
                  id="outlined-basic"
                  data-test-id="txtInputemail"
                  label="Message"
                  variant="outlined"
                  value={this.state.message}
                />
              </Grid>
              <></>
            </Grid>
          </>
        );

      default:
        return "Unknown step";
    }
  }

  // Customizable Area Start
  getStepContents(step: number) {
        return(<>
        <MultiformContainer>
          <Box className="header">
              <Box></Box>
              <img src={require("../assets/view_logo.png").default} height="15px" width="82px" />
              <img src={require("../assets/button_menu.png").default} height="26px" width="38px" />
          </Box>
          <Box className="body">
              <Box className="leftContainer">
                <Box className="tab">
                  <Box>
                    <Typography className="project_title">PROJECT OVERVIEW</Typography>
                    <Typography className="description">Fields marked with “<span style={{color:"red"}}>*</span>” are required.</Typography>
                  </Box>
                  <Formik
                    innerRef={this.formikRef}
                    initialValues={{
                    name: this.state.name, 
                    location: this.state.location,
                    selectedPercentage: this.state.selectedPercentage,
                  }}
                  data-test-id="formik"
                  validateOnBlur={false}
                  validateOnChange={false}
                  onSubmit={(values, { setSubmitting, setErrors }) => {  
                    const errors:{ name?:string,selectedPercentage?:string} = {};
                    if (!values.name) {
                      errors.name = "Name is required";
                    }  
                    if(!values.selectedPercentage){
                      errors.selectedPercentage = "Parcentage required"
                    }      
          
                    if (Object.keys(errors).length > 0) {
                      setErrors(errors);
                      setSubmitting(false);
                      return ;
                    }

                    this.handleSaveFinalChanges();
                  }}
            >
            {({ values, errors, setFieldValue, handleSubmit, setErrors }) => (
            <form className="form" onSubmit={handleSubmit}>
                <Box className="contentContainer">
                    <Box className="field">
                        <Typography className="fieldTitle">PROJECT NAME</Typography>
                        <InputField
                        style={{ boxShadow: errors.name ? "0px 0px 3px 0px #C5475B" : "" }}
                        data-test-id="nameField"
                        name="name"
                        fullWidth={true}
                        disableUnderline={true}
                        value={this.state.name}
                        onChange={(e:{target:{value:string}}) => { this.setName(e.target.value); setFieldValue("name", e.target.value); setErrors({...errors, name:""}); }}
                        />
                    </Box>
                    <Box className="field">
                        <Typography className="fieldTitle">LOCATION</Typography>
                        <InputField
                        data-test-id="phoneNumberField"
                        name="location"
                        type="text"
                        fullWidth={true}
                        disableUnderline={true}
                        value={values.location}
                        onChange={(e:{target:{value:string}}) => { this.setPhoneNumber(e.target.value); setFieldValue("location", e.target.value) }}
                        />
                    </Box>
                    <Box className="field" style={{marginBottom:"3rem"}}>
                        <Typography className="fieldTitle">IMAGE</Typography>
                        <Box className="imgfields">
                        <ImageUploader onImageUpload={this.handleImageFile} />
                        </Box>
                    </Box>
                    <Box className="field">
                        <Typography className="fieldTitle">CONTRACT SIGN DATE</Typography>
                        <Box style={{ position: "relative" }}>
                          <Box data-test-id="contractField" className="fieldValue" style={{ height: "36px", borderRadius: this.state.openContractCalendar ? '12px 12px 0 0' : '', "boxShadow": this.state.openContractCalendar ? "rgba(114, 116, 157, 0.4) -4px 1px 14px 0px" : ""}} onClick={this.openContactCalendar}>{this.state.contractCalendarValue}<img className="editPassword" src={require("../assets/calenderimg.png").default}/></Box>
                          { this.state.openContractCalendar && <Calendar selectedDate={this.state.contractCalendarValue} onChangeHandler={this.handleContactCalender} handleclose={this.closeContactCalendar} /> }
                        </Box>

                    </Box>
                    <Box className="field">
                        <Typography className="fieldTitle">COMPLETION DATE</Typography>
                        <Box style={{ position: "relative"}}>
                          <Typography data-test-id="completionField" className="fieldValue" style={{ height: "36px", borderRadius: this.state.openCompletionCalendar ? '12px 12px 0 0' : '', "boxShadow": this.state.openCompletionCalendar ? "rgba(114, 116, 157, 0.4) -4px 1px 14px 0px" : "" }} onClick={this.openCompletionCalendar}>{this.state.completionCalendarValue}<img className="editPassword" src={require("../assets/calenderimg.png").default}/></Typography>
                          { this.state.openCompletionCalendar && <Calendar selectedDate={this.state.completionCalendarValue} onChangeHandler={this.handleCompletionCalender} handleclose={this.closeCompletionCalendar}/> }
                        </Box>
                    </Box>
                    <Box className="field">
                    <Typography className="fieldTitle">DLP DURATION<span><img style={{paddingBottom:"8px"}} src={require("../assets/info_img.png").default}/></span></Typography>
                    <TextField
                      select
                      className="fieldValue"
                      name="selectedPercentage"
                      style={{boxShadow: errors.selectedPercentage ? "0px 0px 3px 0px #C5475B" : "", height: "36px" }}
                      value={values.selectedPercentage}
                      onChange={(e: { target: { value: string } }) => {
                        this.setDlpDuration(e.target.value);
                        setFieldValue("selectedPercentage", e.target.value);
                        setErrors({ ...errors, selectedPercentage: "" }); 
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-root': {
                          height: '18px',
                        },
                      }}
                    >
                      {this.state.percentage.map((option: { label: string; value: string }) => (
                        <MenuItemStyled key={option.value} value={option.value}>
                          {option.label}
                        </MenuItemStyled>
                      ))}
                    </TextField>

                    </Box>
                    <Box>
                      {/* <AddingInputs/> */}
                    </Box>
                </Box>
                <Box>
                </Box>
            </form>
            )}
                  </Formik>
                </Box>
              </Box>
              <img src={require("../assets/projectoverview_carosal.png").default} />
          </Box>
          <Box className="footerContainer">
            <Box style={{marginRight:"175px"}}>
              <img className="arrows" src={require("../assets/left_arrow.png").default}/>
              <img className="arrows" src={require("../assets/right_arrow.png").default}/>
            </Box>
            <Box className="footerContainer">
              { this.state.isDirty && 
            <Box className="footer">
                <Typography data-test-id="clearDetails" onClick={this.cancelPopupHandler} >CANCEL</Typography>
                <Typography data-test-id="saveSubmitBtn" onClick={this.formikRef.current && this.formikRef.current.submitForm}>SAVE AND EXIT</Typography>
            </Box>
              }
            </Box>
          </Box>
        </MultiformContainer>
        <CancelWarningPopup   open={this.state.cancelPopupBoolean} title ={"Are you sure you want to leave? Any unsaved changes will be lost."} btnText= {"Leave Now"}
            onCancel={this.cancelPopup}
            onConfirm={this.confirmCancelPop}
        />
        </>)
    
  }

  steps = this.getSteps();
  // Customizable Area End

  render() {
    if (!this.state?.token_local) {
      return (
        <>
          <h3 style={{ color: "red" }}>Token expired</h3>
          <Button
            data-test-id="btnLogin"
            style={{ backgroundColor: "blue", color: "white" }}
            onClick={this.LoginPage}
          >
            Sign In
          </Button>
        </>
      );
    }
    return (
      // Customizable Area Start
      <>
        <div style={{ width: "100%" }}>
          <div>
            {this.state.activeSteps === this.steps.length ? (
              <div>
                <p>All steps completed</p>
              </div>
            ) : (
              <div>{this.getStepContents(this.state.activeSteps)}</div>
            )}
          </div>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  boxStyle: {
    maxWidth: "620px",
    margin: "0 auto",
  },
  successMsg: {
    color: "red",
    display: "flex",
    justifyContent: "center",
  },
  errorMsg: {
    color: "red",
    fontSize: "14px",
    paddingTop: "5px",
  },
};

const MenuItemStyled = styled(MenuItem)({
 '& .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline':{
  border:"none"
 }
});

const MultiformContainer = styled(Box)({
  padding: "15px",
  paddingBottom: "0",
  boxSizing: "border-box",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  '& .header':{
      display: "flex",
      justifyContent: "space-between",
      padding: "0px 25px",
      paddingTop: "18px",
      boxSizing: "border-box",
      paddingBottom: "3px"
  },
  '& .body':{
      display: "flex",
      flexGrow: 1,
      justifyContent: "space-between"
  },
  '& .leftContainer':{
      padding: "96px 20px 60px 45px",
      '& .description':{
          marginTop: "8px",
          fontFamily: "Avenir Light",
          fontSize: "16px",
          fontWeight: 300,
          lineHeight: "24px",
          color: "#4F506D"
      },
      '& .project_title':{
        marginTop: "8px",
        fontFamily: "Avenir Book",
        fontSize: "20px",
        fontWeight: 350,
        lineHeight: "24px",
        color: "#B3BFDB"
      },
      '& .contentContainer':{
          marginTop: "48px",
          paddingLeft: "28px",
          gap: "28px",
          display: "flex",
          flexDirection: "column"
      },
      '& .field':{
          display: "flex",
          alignItems: "center",
          position: "relative",
          '& .fieldTitle':{
              fontSize: "16px",
              fontWeight: 600,
              lineWeight: "24px",
              letterSpacing: "0.2em",
              color: "#000000",
              width: "300px",
              fontFamily: "Avenir Next DemiBold",
          },
          '& .imgfields':{
              display:"flex",
              justifyContent:"space-between",
              alignItem:"center",
              width:"43%"
          },
          '& .fieldValue':{
              fontFamily: "Avenir Light",
              display: "flex",
              width: "256px",
              fontSize: "14px",
              fontWeight: 300,
              lineHeight: "16px",
              color: "#696A92",
              padding: "10px 12px",
              borderRadius: "12px",
              boxSizing: "border-box",
              boxShadow: "-1.2px 1px 5px 0px #C9CADC, 0.2px 0px 1.8px 0px #E7E8EF",
          },
          '& .fieldValue:hover':{
              boxShadow: "-4px 1px 14px 0px #72749D66"
          },
          '& .fieldValue:hover .block':{
              display: "flex"
          },
          '& .logoImg':{
              boxShadow: "-1.2px 1px 5px 0px #C9CADC, 0.2px 0px 1.8px 0px #E7E8EF",
              padding: "26px 24px",
              borderRadius: "12px"
          },
          '& .logoImg:hover':{
              boxShadow: "-4px 1px 14px 0px #72749D66"
          },
          '& .block':{
              width: "24px",
              height: "24px",
              color: "#DADADA",
              display: "none",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: "8px",
              marginTop: "auto",
              marginBottom: "auto",
          },
          '& .editPassword':{
              width: "16px",
              height: "16px",
              color: "#DADADA",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: "12px",
              marginTop: "auto",
              marginBottom: "auto",
              cursor: "pointer"
          },
          '& .daysLeft':{
              fontFamily: "Avenir Light",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "18px",
              marginLeft: "auto"
          }
      }
  },
  '& .rightContainer':{
      height: "100%",
      '& img':{
          height: "100%"
      }
  },
  '& .tab':{
      '& .MuiTabs-indicator': {
          display: 'none',
      },
      '& .MuiTabs-root':{
          minHeight: "fit-content"
      },
      '& .MuiTabs-flexContainer':{
          gap: "32px"
      },
      '& .MuiTab-root':{
          minWidth: "fit-content",
          minHeight: "fit-content",
          padding: 0,
          fontFamily: "Avenir Next Medium",
          fontSize: "20px",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing:"0.2em",
          color: "#B3BFDB",
          opacity: 0.5
      },
      '& .MuiTab-textColorInherit.Mui-selected':{
          opacity: 1
      }
  },
  '& .footerContainer':{
      paddingBottom: "15px",
      paddingTop: "4px",
      minHeight: "53px",
      display: "flex",
      alignContent: "center",
      justifyContent: "end"
    },
    '& .arrows':{
      padding:"0px 20px"
    },
  '& .footer':{
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      '& p':{
          marginLeft: "auto",
          fontFamily: "Avenir Next DemiBold",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "24px",
          letterSpacing: "0.2em",
          color: "#B3BFDB",
          paddingRight: "30px",
          cursor: "pointer"
      }
  },
  '& .errorMessage':{
      fontSize: "12px",
      fontWeight: 400,
      width: "256px",
      textAlign: "left",
      color: "#C5475B",
      display: "flex",
      marginLeft: "auto",
      marginTop: "60px",
      fontFamily: "Avenir Next Medium",
      '& p':{
          margin: 0,
          marginLeft: "5px",
          height: "14px",
      },
      '& .MuiSvgIcon-root':{
          height: "16px",
          width: "18px" 
      }
  },
})

const InputField = styled(Input)({
  fontFamily: "Avenir Light",
  display: "flex",
  width: "256px",
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "16px",
  color: "#696A92",
  padding: "10px 12px",
  borderRadius: "12px",
  boxSizing: "border-box",
  height: "36px",
  boxShadow: "-1.2px 1px 5px 0px #C9CADC, 0.2px 0px 1.8px 0px #E7E8EF",
  '& .MuiInputBase-input':{
      padding: 0
  },
  '&.Mui-focused, &:hover': {
      boxShadow: "-4px 1px 14px 0px #72749D66"
  },
  '& .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline':{
  border:"none"
 }
})
// Customizable Area End
