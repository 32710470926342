import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

interface ImageUploaderProps {
    onImageUpload: (file: File | null) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageUpload }) => {
    const [uploadedImage, setUploadedImage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size > 10 * 1024 * 1024) {
                setErrorMessage("Please upload an image of less than 10 MB.");
                setUploadedImage(null);
                onImageUpload(null);
                return;
            }
            if (file.type === "image/jpeg" || file.type === "image/png") {
                const reader = new FileReader();
                reader.onload = () => {
                    if (typeof reader.result === "string") {
                        setUploadedImage(reader.result); 
                        setErrorMessage(""); 
                        onImageUpload(file);
                    }
                };
                reader.readAsDataURL(file);
            } else {
                setErrorMessage("Please upload a valid JPG or PNG image.");
                setUploadedImage(null);
                onImageUpload(null);
            }
        }
    };

    const handleRemoveImage = () => {
        setUploadedImage(null);
        setErrorMessage("");
        onImageUpload(null);
    };

    return (
        <Box className="imgfields" style={{ textAlign: "center" }}>
            <input
                type="file"
                accept=".jpg,.png"
                style={{ display: "none" }}
                id="upload-image"
                onChange={handleImageUpload}
            />
            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                <Box
                    style={{
                        border: errorMessage ? "2px solid red" : "none",
                        padding: "5px",
                        display: uploadedImage ? "none" : "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "4rem"
                    }}
                >
                    <img
                        src={require("../../assets/uploadimg.png").default}
                        alt="Upload"
                        style={{ cursor: "pointer", objectFit: "scale-down" }}
                        onClick={() => document.getElementById("upload-image")?.click()}
                        data-test-id="upload-image"
                    />
                    <img style={{objectFit:"contain", display: uploadedImage ? "none" : "inline-block"}} src={require("../../assets/imgemoji.png").default} />
                </Box>
                {uploadedImage && (
                    <div
                        style={{
                            marginTop: "10px",
                            position: "relative",
                            display: "inline-block",
                        }}
                    >
                        <img
                            src={uploadedImage}
                            alt="Uploaded"
                            style={{
                                width: "114px",
                                height: "64px",
                                objectFit: "cover",
                                border: "1px solid #ccc",
                                borderRadius: "12px",
                            }}
                        />
                        <button
                            style={{
                                position: "absolute",
                                top: "-5px",
                                right: "-5px",
                                background: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            data-test-id="remove_image"
                            onClick={handleRemoveImage}
                        >
                            &times;
                        </button>
                    </div>
                )}
            </div>
            {errorMessage && (
                <Typography
                    style={{
                        color: "red",
                        marginTop: "10px",
                        fontSize: "14px",
                        textAlign: "center",
                    }}
                >
                    {errorMessage}
                </Typography>
            )}
        </Box>
    );
};

export default ImageUploader;
